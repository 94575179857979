import React from 'react';
import { graphql } from 'gatsby';
import Markdown from "markdown-to-jsx";

import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";
import Layout from "../../components/layouts/Layout";
import TechnicalSupportCard from "../../components/cards/TechnicalSupportCard";

import Col from "../../components/styled/grid/Col";
import {Header3, Span} from "../../components/styled/typography/Typography";

const SupportLevel = ({data}) => {

    const {support_level} = data;

    return (
        <Layout lang={support_level.locale} seo={support_level.SEO}>
            <SectionLayoutGrid customBackground="middle">
                <Col className="markdown">
                    <Markdown children={support_level.content}/>
                </Col>
                <Col>
                    <Header3 color="white" upper>
                        Base<Span color="primary-brand">SU</Span> / Standard<Span color="primary-brand">SU</Span>
                    </Header3>
                </Col>
                <TechnicalSupportCard
                    number={"1"}
                    response={support_level.locale === "hu" ? "Következő munkanap" : "Next business day"}
                    communication={support_level.locale === "hu" ? "Naponta egyszer, a Balasys munkaidejében, a megfelelő áthidaló megoldás megtalálásáig" : "Once a day, in Balasys working time, until the appropriate workaround is found"}
                    lang={support_level.locale}
                />
                <TechnicalSupportCard
                    number={"2"}
                    response={support_level.locale === "hu" ? "2 munkanap" : "2 business days"}
                    communication={support_level.locale === "hu" ? "Hetente egyszer, a Balasys munkaidejében" : "Once a week, in Balasys working time"}
                    lang={support_level.locale}
                />
                <TechnicalSupportCard
                    number={"3"}
                    response={support_level.locale === "hu" ? "1 hét" : "1 week"}
                    communication={support_level.locale === "hu" ? "Havonta egyszer, a Balasys munkaidejében" : "Once a month, in Balasys working time"}
                    lang={support_level.locale}
                />
                <Col>
                    <Header3 color="white" upper>
                        PRIVILEGE<Span color="primary-brand">SU</Span> / 24x7 <Span color="primary-brand">SU</Span>
                    </Header3>
                </Col>
                <TechnicalSupportCard
                    number={"1"}
                    response={support_level.locale === "hu" ? "2 óra" : "2 hours"}
                    communication={support_level.locale === "hu" ? "2 óránként, heti 7*24 órában, a megfelelő áthidaló megoldás azonosításáig" : "Every 2 hours, in 7*24 hours per week, until the appropriate workaround is found"}
                    lang={support_level.locale}
                />
                <TechnicalSupportCard
                    number={"2"}
                    response={support_level.locale === "hu" ? "Következő munkanap" : "Next business day"}
                    communication={support_level.locale === "hu" ? "Naponta egyszer, a Balasys munkaidejében" : "Once a day, in Balasys working time"}
                    lang={support_level.locale}
                />
                <TechnicalSupportCard
                    number={"3"}
                    response={support_level.locale === "hu" ? "Következő munkanap" : "Next business day"}
                    communication={support_level.locale === "hu" ? "Hetente egyszer, a Balasys munkaidejében" : "Once a week, in Balasys working time"}
                    lang={support_level.locale}
                />
                <Col className="markdown">
                    <Markdown children={support_level.content_down}/>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    );
};

export const query = graphql`
   query GetSingleSupportLevel($locale: String) {
         support_level: strapiSupportLevel(locale: { eq: $locale }) {
              locale
              content
              SEO {
                title
                isIndexable
                description
                keywords
                preview {
                  url
                }
              }
              content_down
         }
   }
 `

export default SupportLevel;