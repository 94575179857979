import React from "react"

import Col from "../styled/grid/Col";
import {
    TechnicalSupportCardContainer, TechnicalSupportCardLevel, TechnicalSupportCardTitle,
    TechnicalSupportCardWrapper
} from "../styled/cards/TechnicalSupportCardComponents";
import {Body} from "../styled/typography/Typography";

const TechnicalSupportCard = ({number, response, communication, lang}) => {

    return (
        <TechnicalSupportCardWrapper>
            <TechnicalSupportCardContainer>
                <TechnicalSupportCardLevel>S{number}</TechnicalSupportCardLevel>
                <TechnicalSupportCardTitle color="body-text" upper bold textAlign="center" small>
                    {lang === "hu" ? "Súlyosság" : "Severity"}
                </TechnicalSupportCardTitle>
                <Col marginBottom="small">
                    <TechnicalSupportCardTitle color="body-text" upper bold small>
                        {lang === "hu" ? "VÁLASZIDŐ" : "Initial Response"}
                    </TechnicalSupportCardTitle>
                    <Body color="body-text">
                        {response}
                    </Body>
                </Col>
                <Col marginBottom="small">
                    <TechnicalSupportCardTitle color="body-text" upper bold small>
                        {lang === "hu" ? "KOMMUNIKÁCIÓS GYAKORISÁG" : "Communication Frequency"}
                    </TechnicalSupportCardTitle>
                    <Body color="body-text">
                        {communication}
                    </Body>
                </Col>
            </TechnicalSupportCardContainer>
        </TechnicalSupportCardWrapper>
    )
}

export default TechnicalSupportCard