import styled from "styled-components";
import {Body} from "../typography/Typography";

export const TechnicalSupportCardWrapper = styled.div`
  border: 1px solid rgba(13, 65, 121, 0.5);
  padding: 20px;
  background-color: transparent;
  grid-column: span 4;

  @media screen and (max-width: 800px) {
    grid-column: 1/-1 !important;
  }
`

export const TechnicalSupportCardContainer = styled.div`
  background-color: rgba(13, 65, 121, 0.7);
  padding: 0 40px 0 40px;
  display: grid;
  align-content: start;
  height: 100%;
  grid-template-rows: auto 50px 100px auto;

  @media screen and (max-width: 800px) {
    grid-template-rows: auto 25px 50px auto !important;
  }
`

export const TechnicalSupportCardTitle = styled(Body)`
  opacity: 0.3;
`

export const TechnicalSupportCardLevel = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: var(--title);
  line-height: var(--title-lh);
  text-transform: uppercase;
  color: var(--primary-brand);
  text-align: center;
  margin-top: 2rem;
`